import React from 'react'
import logo from "../../assets/images/logo.png"
import footerMap from "../../assets/images/footer-map.png"
import { Link } from "react-router-dom";

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <Link to="/" className="logo">
                                <img src={logo} alt="logo" width={200} />
                            </Link>
                            <p>{t('為DSE學生的未來分析最適合選擇')}</p>

                            <ul className="social-link">
                                <li>
                                    <a href="https://www.facebook.com/rightpickhk" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook' />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/rightpickhk" className="d-block" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram' />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>{t('瀏覽')}</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/about-us">
                                        {t('關於我們')}
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/case-studies">
                                        {t('個案分析')}
                                    </Link>
                                </li>

                                
                                <li>
                                    <Link to="/user-experiences">
                                        {t('用家感受')}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/faq">
                                        {t('常見問題')}
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/blog">
                                        {t('Blog')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-6">
                        <div className="single-footer-widget">
                            <h3>{t('聯絡我們')}</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className="bx bx-map" />
                                    Workstation 15, Cyberport, 7/F - 8/F, HS Centre, 5 Lok Yip Road, Fanling, NT
                                </li>
                                <li>
                                    <i className='bx bx-phone-call' />
                                    <a href="tel:+85256339157">+852 5633 9157</a>
                                    {/* <a href="tel:+85267475860">+852 6747 5860</a> */}
                                </li>
                                <li>
                                    <i className='bx bx-envelope' />
                                    <a href="mailto:rp@rightpickhk.com">rp@rightpickhk.com</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>© {currentYear} <strong>RightPick</strong>  All rights reserved </p>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li className="nav-item">
                                    <Link
                                        to="/terms-of-service"
                                    >
                                        {t('條款及細則')}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;