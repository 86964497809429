import React from "react";
import { getSurveyHistoryList, getGaoKaoHistoryList, getCareerHistoryList, getCharacterHistoryList, getSalaryHistoryList } from "../functions/Survey";

export const delay = ms => new Promise(k => setTimeout(k, ms));

// #region fetch data
export function useSHistory() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [surveyHistoryList, setSurveyHistoryList] = React.useState([]);
    React.useEffect(() => {
        const initSurveyHistory = async () => {
            const surveyHistoryList = await getSurveyHistoryList();
            setSurveyHistoryList(surveyHistoryList);
        };
        initSurveyHistory().then(() => {
            setIsLoading(false);
        });
    }, []);

    return [surveyHistoryList, isLoading];
}

// gaokao fetch data
export function useGaoKaoHistory() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [surveyHistoryList, setSurveyHistoryList] = React.useState([]);
    React.useEffect(() => {
        const initSurveyHistory = async () => {
            const surveyHistoryList = await getGaoKaoHistoryList();
            setSurveyHistoryList(surveyHistoryList);
        };
        initSurveyHistory().then(() => {
            setIsLoading(false);
        });
    }, []);

    return [surveyHistoryList, isLoading];
}

// #region fetch data
export function useCareerHistory() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [careerHistoryList, setcareerHistoryList] = React.useState([]);
    React.useEffect(() => {
        const initSurveyHistory = async () => {
            const surveyHistoryList = await getCareerHistoryList();
            setcareerHistoryList(surveyHistoryList);
        };
        initSurveyHistory().then(() => {
            setIsLoading(false);
        });
    }, []);

    return [careerHistoryList, isLoading];
}
export function useCharacterHistory() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [characterHistory, setCharacterHistory] = React.useState([]);
    React.useEffect(() => {
        const initCharacterHistory = async () => {
            const characterHistoryList = await getCharacterHistoryList();
            setCharacterHistory(characterHistoryList);
        };
        initCharacterHistory().then(() => {
            setIsLoading(false);
        });
    }, []);

    return [characterHistory, isLoading];
}
export function useSalaryHistory() {
    const [isLoading, setIsLoading] = React.useState(true);
    const [salaryHistoryList, setSalaryHistory] = React.useState([]);
    React.useEffect(() => {
        const initSalaryHistory = async () => {
            const salaryHistoryListData = await getSalaryHistoryList();
            setSalaryHistory(salaryHistoryListData);
        };
        initSalaryHistory().then(() => {
            setIsLoading(false);
        });
    }, []);

    return [salaryHistoryList, isLoading];
}




// #endregion fetch data

export const cns = (...ls) => ls.filter(Boolean).join(' ');

/**
 * @param { Date | string } dateOrString
 * @return {string}
 */
// #region format date

export function isValidDate(dateOrStr) {
    const d = new Date(dateOrStr);
    return d instanceof Date && !isNaN(d.getTime());
}

export const formatDate = function (dateOrString) {
    const date = new Date(dateOrString);
    if (isValidDate(date)) {
        const locale = 'en-US';
        return date.toLocaleDateString([locale], {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        });
    } else {
        return '';
    }
};
// #endregion format date

export const thousandcomma = (value) => {
    return value.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
}
